<template>
  <CSidebar
    color-scheme="light"
    size="lg"
    overlaid
    placement="end"
    :visible="asideVisible"
  >
    <CSidebarHeader class="bg-transparent p-0">
      <CNav variant="underline">
        <CNavItem>
          <CNavLink>
            <CIcon icon="cil-bell" alt="CoreUI Icons List" />&nbsp;
            <CBadge>0</CBadge>
          </CNavLink>
        </CNavItem>
        <CNavItem class="ms-auto me-2 d-flex align-items-center">
          <CCloseButton @click="$store.commit('toggleAside')" />
        </CNavItem>
      </CNav>
    </CSidebarHeader>

        <CListGroup flush>
          <CListGroupItem class="list-group-item list-group-item border-start-4 border-start-secondary bg-light dark:bg-white dark:bg-opacity-10 dark:text-medium-emphasis text-center fw-bold text-medium-emphasis text-uppercase small">NEW Notifications&nbsp;
            <CBadge color="primary">0</CBadge>
            &nbsp;&nbsp;
            <CIcon icon="cil-reload" color="primary" size="sm" />
          </CListGroupItem>
        </CListGroup>

  </CSidebar>

</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import axios from "axios"
import vueCookies from 'vue-cookies'


export default {
  name: 'AppAside',
  setup() {

    const store = useStore()

    return {
      store,
      asideVisible: computed(() => store.state.asideVisible),
    }
  },

  data: () => {
    return {
      // headerToken
      APIHOST: process.env.VUE_APP_APIS_HOST === undefined ? '' : process.env.VUE_APP_APIS_HOST,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': vueCookies.get('accessToken')
      },

    }
  },

  methods: {
    
  },

  created() {
    
  }, // end created

  mounted: function() {
    
  },

  beforeDestroy() {
    
  }

}
</script>

<style scoped>

  .alert-fixed {
    width: 10rem;
    position:fixed; 
    bottom: 5px; 
    left: 15px; 
    width: 10%;
    z-index:9999; 
    border-radius:0px
  }

</style>
