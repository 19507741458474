export const sygnet = [
  '160 160',
  `
  <title>coreui logo</title>
  <g id="Layer-1" serif:id="Layer 1" transform="matrix(1.09722,0,0,1.09722,1.00044,6.98725)">
        <g transform="matrix(1,0,0,1,97.7976,99.0445)">
            <path d="M0,-65.929L0,-4.398L-14.663,-65.929L-48.906,-65.929L-48.906,-4.398L-63.599,-65.929L-97.798,-65.929L-97.798,0L-79.856,0L-79.856,-61.516L-65.178,0L-30.949,0L-30.949,-61.516L-16.256,0L17.941,0L17.941,-65.929L0,-65.929Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(0,-1,-1,0,134.045,80.0614)">
            <path d="M-9.955,-9.955C-15.453,-9.955 -19.91,-5.498 -19.91,0C-19.91,5.498 -15.453,9.955 -9.955,9.955C-4.457,9.955 0,5.498 0,0C0,-5.498 -4.457,-9.955 -9.955,-9.955" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
    </g>
`,
]
