export const logoNegative = [
  '642 134',
  `
  <title>Connectco</title>
  <g id="Layer-1" serif:id="Layer 1" transform="matrix(3.34249,0,0,3.34249,-9.94364,-250.126)">
        <g transform="matrix(1,0,0,1,13.9651,99.5385)">
            <path d="M0,-20.673C5.788,-20.673 9.637,-17.593 10.236,-12.633L4.79,-12.633C4.335,-14.799 2.366,-15.883 0.058,-15.883C-2.993,-15.883 -5.247,-14.201 -5.247,-10.352C-5.247,-6.473 -2.993,-4.791 0.058,-4.791C2.366,-4.791 4.335,-5.903 4.79,-8.071L10.236,-8.071C9.637,-3.08 5.788,0 0,0C-6.587,0 -10.691,-4.478 -10.691,-10.352C-10.691,-16.197 -6.587,-20.673 0,-20.673" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,36.6333,83.6562)">
            <path d="M0,11.091C3.422,11.091 5.133,9.066 5.133,5.53C5.133,2.024 3.422,-0.001 0,-0.001C-3.42,-0.001 -5.161,2.024 -5.161,5.53C-5.161,9.066 -3.42,11.091 0,11.091M0,-4.791C6.559,-4.791 10.58,-0.314 10.58,5.53C10.58,11.404 6.559,15.882 0,15.882C-6.587,15.882 -10.606,11.404 -10.606,5.53C-10.606,-0.314 -6.587,-4.791 0,-4.791" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,87.4077,99.2529)">
            <path d="M0,-20.103L15.084,-20.103L15.084,-15.312L5.447,-15.312L5.447,-12.461L13.659,-12.461L13.659,-7.67L5.447,-7.67L5.447,-4.791L15.084,-4.791L15.084,0L0,0L0,-20.103Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,114.206,99.5385)">
            <path d="M0,-20.673C5.788,-20.673 9.637,-17.593 10.236,-12.633L4.79,-12.633C4.335,-14.799 2.366,-15.883 0.058,-15.883C-2.993,-15.883 -5.247,-14.201 -5.247,-10.352C-5.247,-6.473 -2.993,-4.791 0.058,-4.791C2.366,-4.791 4.335,-5.903 4.79,-8.071L10.236,-8.071C9.637,-3.08 5.788,0 0,0C-6.587,0 -10.691,-4.478 -10.691,-10.352C-10.691,-16.197 -6.587,-20.673 0,-20.673" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,143.111,94.4618)">
            <path d="M0,-10.521L-6.415,-10.521L-6.415,4.791L-11.862,4.791L-11.862,-10.521L-18.277,-10.521L-18.277,-15.312L0,-15.312L0,-10.521Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,154.176,99.5385)">
            <path d="M0,-20.673C5.788,-20.673 9.637,-17.593 10.236,-12.633L4.79,-12.633C4.335,-14.799 2.366,-15.883 0.058,-15.883C-2.993,-15.883 -5.247,-14.201 -5.247,-10.352C-5.247,-6.473 -2.993,-4.791 0.058,-4.791C2.366,-4.791 4.335,-5.903 4.79,-8.071L10.236,-8.071C9.637,-3.08 5.788,0 0,0C-6.587,0 -10.691,-4.478 -10.691,-10.352C-10.691,-16.197 -6.587,-20.673 0,-20.673" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,176.768,83.6513)">
            <path d="M0,11.099C-3.412,11.099 -5.158,9.072 -5.158,5.54C-5.158,2.027 -3.412,0 0,0C3.422,0 5.138,2.027 5.138,5.54C5.138,9.072 3.422,11.099 0,11.099M0,-4.787C-6.583,-4.787 -10.597,-0.311 -10.597,5.54C-10.597,11.41 -6.583,15.886 0,15.886C6.563,15.886 10.587,11.41 10.587,5.54C10.587,-0.311 6.563,-4.787 0,-4.787" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,84.8607,99.2543)">
            <path d="M0,-20.108L0,0L-10.43,0L-14.911,-18.762L-14.911,0L-25.351,0L-29.828,-18.762L-29.828,0L-35.3,0L-35.3,-20.108L-24.87,-20.108L-20.388,-1.341L-20.388,-20.108L-9.944,-20.108L-5.472,-1.341L-5.472,-20.108L0,-20.108Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(0,-1,-1,0,191.712,93.4643)">
            <path d="M-3.037,-3.037C-4.714,-3.037 -6.073,-1.678 -6.073,-0.001C-6.073,1.676 -4.714,3.037 -3.037,3.037C-1.36,3.037 -0.001,1.676 -0.001,-0.001C-0.001,-1.678 -1.36,-3.037 -3.037,-3.037" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,45.8921,108.56)">
            <path d="M0,-0.246L0.945,-0.246C1.424,-0.246 1.676,-0.511 1.676,-0.92C1.676,-1.343 1.412,-1.582 0.958,-1.582L0,-1.582L0,-0.246ZM-0.945,-2.376L1.033,-2.376C1.557,-2.376 1.947,-2.225 2.212,-1.954C2.464,-1.695 2.615,-1.343 2.615,-0.927C2.615,-0.076 2.054,0.498 1.109,0.498L0,0.498L0,2.13L-0.945,2.13L-0.945,-2.376Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,0,24.874)">
            <rect x="53.761" y="81.31" width="0.914" height="4.506" style="fill:rgb(184,219,33);"/>
        </g>
        <g transform="matrix(1,0,0,1,59.9722,108.428)">
            <path d="M0,0.024C0,-1.312 0.832,-2.332 2.162,-2.332C3.271,-2.332 3.926,-1.671 4.021,-0.814L3.113,-0.814C3.031,-1.268 2.691,-1.564 2.162,-1.564C1.33,-1.564 0.933,-0.871 0.933,0.024C0.933,0.944 1.412,1.606 2.167,1.606C2.704,1.606 3.082,1.285 3.138,0.812L4.033,0.812C4.008,1.209 3.838,1.593 3.535,1.877C3.227,2.167 2.792,2.356 2.167,2.356C0.901,2.356 0,1.379 0,0.024" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,69.272,110.69)">
            <path d="M0,-4.506L0.914,-4.506L0.914,-2.71L2.646,-4.506L3.781,-4.506L2.017,-2.703L3.895,0L2.823,0L1.374,-2.105L0.914,-1.639L0.914,0L0,0L0,-4.506Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,85.0093,108.56)">
            <path d="M0,-0.246L0.945,-0.246C1.424,-0.246 1.676,-0.511 1.676,-0.92C1.676,-1.343 1.412,-1.582 0.958,-1.582L0,-1.582L0,-0.246ZM-0.945,-2.376L1.033,-2.376C1.557,-2.376 1.947,-2.225 2.212,-1.954C2.464,-1.695 2.615,-1.343 2.615,-0.927C2.615,-0.076 2.054,0.498 1.109,0.498L0,0.498L0,2.13L-0.945,2.13L-0.945,-2.376Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,93.5713,107.84)">
            <path d="M0,1.192L1.191,1.192L0.788,-0.107C0.712,-0.346 0.605,-0.799 0.605,-0.799L0.593,-0.799C0.593,-0.799 0.479,-0.346 0.403,-0.107L0,1.192ZM0.132,-1.657L1.078,-1.657L2.666,2.849L1.714,2.849L1.412,1.891L-0.214,1.891L-0.51,2.849L-1.437,2.849L0.132,-1.657Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,100.962,108.428)">
            <path d="M0,0.024C0,-1.312 0.832,-2.332 2.162,-2.332C3.271,-2.332 3.926,-1.671 4.021,-0.814L3.113,-0.814C3.031,-1.268 2.691,-1.564 2.162,-1.564C1.33,-1.564 0.933,-0.871 0.933,0.024C0.933,0.944 1.412,1.606 2.168,1.606C2.704,1.606 3.082,1.285 3.138,0.812L4.033,0.812C4.008,1.209 3.838,1.593 3.535,1.877C3.227,2.167 2.792,2.356 2.168,2.356C0.901,2.356 0,1.379 0,0.024" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,110.262,110.69)">
            <path d="M0,-4.506L0.914,-4.506L0.914,-2.71L2.646,-4.506L3.781,-4.506L2.017,-2.703L3.895,0L2.823,0L1.374,-2.105L0.914,-1.639L0.914,0L0,0L0,-4.506Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,124.852,107.595)">
            <path d="M0,1.702L0.895,1.702C0.958,2.244 1.267,2.458 1.909,2.458C2.375,2.458 2.785,2.294 2.785,1.879C2.785,1.437 2.357,1.348 1.67,1.191C0.863,1.009 0.126,0.795 0.126,-0.145C0.126,-1.033 0.851,-1.493 1.853,-1.493C2.867,-1.493 3.522,-0.995 3.592,-0.088L2.716,-0.088C2.666,-0.541 2.313,-0.769 1.846,-0.769C1.355,-0.769 1.033,-0.561 1.033,-0.239C1.033,0.127 1.349,0.233 2.017,0.379C2.943,0.58 3.699,0.801 3.699,1.771C3.699,2.685 2.962,3.195 1.947,3.195C0.725,3.195 0.031,2.653 0,1.702" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,133.894,110.69)">
            <path d="M0,-4.506L0.914,-4.506L0.914,-2.741L2.685,-2.741L2.685,-4.506L3.598,-4.506L3.598,0L2.685,0L2.685,-1.985L0.914,-1.985L0.914,0L0,0L0,-4.506Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,0,24.874)">
            <rect x="142.985" y="81.31" width="0.914" height="4.506" style="fill:rgb(184,219,33);"/>
        </g>
        <g transform="matrix(1,0,0,1,150.336,108.56)">
            <path d="M0,-0.246L0.945,-0.246C1.424,-0.246 1.676,-0.511 1.676,-0.92C1.676,-1.343 1.412,-1.582 0.958,-1.582L0,-1.582L0,-0.246ZM-0.945,-2.376L1.033,-2.376C1.557,-2.376 1.947,-2.225 2.212,-1.954C2.464,-1.695 2.615,-1.343 2.615,-0.927C2.615,-0.076 2.054,0.498 1.109,0.498L0,0.498L0,2.13L-0.945,2.13L-0.945,-2.376Z" style="fill:rgb(184,219,33);fill-rule:nonzero;"/>
        </g>
    </g>
`,
]
