<template>
  <!-- <CFooter>
  </CFooter> -->
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
