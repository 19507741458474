export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },

  // APPS
  {
    component: 'CNavTitle',
    name: 'APPS',
  },
  {
    component: 'CNavGroup',
    name: 'Global Management',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Web Users',
        to: '/webusers',
        icon: 'cil-blur',
      },
    ]
  },
  {
    component: 'CNavGroup',
    name: 'Saras Management',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Saras Plugins',
        to: '/sarasplugins',
        icon: 'cil-blur',
      },
    ]
  },
  {
    component: 'CNavItem',
    name: 'kuber',
    to: '/kuber',
    icon: 'cil-blur',
  },
]
